import { Grid } from '@mui/material'
import CreateModal from '../Modals/Create'
import PlansModal from '../Modals/Plans'
import MUIDataTable from 'mui-datatables'
import MDButton from 'components/MDButton'
import { useState } from 'react'

export default function AppsComponent({ Conjo, onLoad, columns, options }) {
  const [copy, setCopy] = useState(false)

  const copyToClipboard = () => {
    const text = `<script src="https://api.alltheapps.io/api/agency_app/hideAppGlobalConfigue/addCustomValue"></script>`

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    // Select the text inside the textarea
    textarea.select()

    // Execute the copy command
    document.execCommand('copy')
    setCopy(true)
    // Remove the textarea from the DOM
    document.body.removeChild(textarea)

    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12} mb={3} display={'flex'} justifyContent={'flex-end'}>
          {/* <PlansModal handleChange={onLoad} /> */}
          <CreateModal handleChange={onLoad} />
          <MDButton
            sx={{ marginLeft: 3 }}
            variant="outlined"
            color="info"
            onClick={copyToClipboard}
          >
            {copy ? 'Copied' : 'Copy Script'}
          </MDButton>
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable title={'Apps'} data={Conjo} columns={columns} options={options} />
        </Grid>
      </Grid>
    </>
  )
}
