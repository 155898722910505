/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
// import Calenders from 'layouts/Pages/Calenders'
import Integration from 'layouts/integration'
import Survey from 'layouts/Pages/Survey'
import SurveyBuilder from 'layouts/Pages/Survey/components/ServeyBuilder'
import LocConfigure from 'layouts/Pages/Survey/LocConfigure'
import SurveyFormLink from 'layouts/Pages/Survey/components/FormLink'
import Calender from 'layouts/Pages/Calenders'
import CalenderFormLink from 'layouts/Pages/Calenders/components/FormLink'
import CustomFieldUpdater from 'layouts/Pages/CustomFieldUpdater'
import AppsConfigration from 'layouts/Pages/AppsConfigration'
import SnapshotOnBordingv2 from 'layouts/Pages/SnapshotOnBordingv2'
import SnapshotOnBording from 'layouts/Pages/SnapshotOnBording'
import SnapshotOnBordingRedirectPage from 'layouts/Pages/SnapshotOnBordingv2/RedirectPage'

const useRoutes = () => {
  return [
    {
      type: 'collapse',
      key: 'integrations',
      route: '/integrations/*',
      component: <Integration />,
    },
    {
      type: 'collapse',
      key: 'onboarding',
      route: '/:app_id/onboarding/redirect',
      component: <SnapshotOnBordingRedirectPage />,
    },
    {
      type: 'collapse',
      key: 'onboarding',
      route: '/:location_id/:app_id/onboarding/*',
      component: <SnapshotOnBordingv2 />,
    },
    {
      type: 'collapse',
      key: 'onboarding',
      route: 'onboardingv1/*',
      component: <SnapshotOnBording />,
    },
    {
      type: 'collapse',
      key: 'survey',
      route: '/:company_id/:app_id/survey',
      component: <Survey />,
    },
    {
      route: '/:company_id/:app_id/survey/:id',
      component: <SurveyBuilder />,
    },
    {
      route: '/:company_id/:app_id/survey/location_configure/:id',
      component: <LocConfigure />,
    },
    {
      type: 'collapse',
      key: 'survey_form',
      route: '/survey/form/:app_id/:loc_id/:id/:agency_id',
      component: <SurveyFormLink />,
    },
    {
      type: 'collapse',
      key: 'calender',
      route: '/:company_id/:app_id/calender',
      component: <Calender />,
    },
    {
      type: 'collapse',
      key: 'calender_form',
      route: '/calender/:app_id/:id/:agency_id',
      component: <CalenderFormLink />,
    },
    {
      type: 'collapse',
      key: 'Custom Field Updater',
      route: '/customfieldupdater',
      component: <CustomFieldUpdater />,
    },
    {
      type: 'collapse',
      key: 'apps configration',
      route: '/apps/:app_id/:location_id',
      component: <AppsConfigration />,
    },
  ]
}

export default useRoutes
