/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

// @mui material components
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import ComplexProjectCard from 'examples/Cards/ProjectCards/ComplexProjectCard'

// Project page components
// import Header from "layouts/pages/profile/components/Header";

// Images
import team1 from 'assets/images/team-1.jpg'
import team2 from 'assets/images/team-2.jpg'
import team3 from 'assets/images/team-3.jpg'
import team4 from 'assets/images/team-4.jpg'
import team5 from 'assets/images/team-5.jpg'
import logoSlack from 'assets/images/small-logos/logo-slack.svg'
import logoSpotify from 'assets/images/small-logos/logo-spotify.svg'
import logoXD from 'assets/images/small-logos/logo-xd.svg'
import logoAsana from 'assets/images/small-logos/logo-asana.svg'
import logoInvision from 'assets/images/small-logos/logo-invision.svg'
import logoAtlassian from 'assets/images/small-logos/logo-atlassian.svg'
import Header from '../components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppServices } from 'hook/services'
import CreateModal from './Modals/Create'
import Loader from 'examples/Loader'

function AllProjects() {
  const { location_id, app_id } = useParams()
  const Service = useAppServices()
  const navigate = useNavigate()
  // ComplexProjectCard dropdown menu state
  const [processing, setProcessing] = useState(true)
  const [Apps, setApps] = useState([])
  const [slackBotMenu, setSlackBotMenu] = useState(null)

  // TeamProfileCard dropdown menu handlers
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget)
  const closeSlackBotMenu = () => setSlackBotMenu(null)

  const getData = async () => {
    const { response } = await Service.snapshot_onboarding.get({
      query: `location_id=${location_id}&=main_app_id${app_id}`,
    })

    if (response) {
      setApps(response.data)
      console.log(response.data)
      setProcessing(false)
    } else {
      setProcessing(false)
    }
  }
  const onLoad = () => {
    getData()
  }
  useEffect(async () => {
    onLoad()
  }, [])
  const handleNavigate = (id) => {
    navigate(`./${id}`)
  }
  const handleNavigateAppDashboard = (id) => {
    navigate(`./dashboard/${id}`)
  }
  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close, app) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => handleNavigate(app?._id)}>View</MenuItem>
      <MenuItem onClick={() => handleNavigateAppDashboard(app?.app_id)}>Analytics</MenuItem>
      {/* <MenuItem onClick={close}>Another action</MenuItem>
      <MenuItem onClick={close}>Something else here</MenuItem> */}
    </Menu>
  )
  // Copy
  const [copy, setCopy] = useState(false)

  const copyToClipboard = () => {
    const text = `<script src="https://api.alltheapps.io/api/agency_app/hideAppGlobalConfigue/addCustomValue"></script>`

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    // Select the text inside the textarea
    textarea.select()

    // Execute the copy command
    document.execCommand('copy')
    setCopy(true)
    // Remove the textarea from the DOM
    document.body.removeChild(textarea)

    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }

  return (
    <>
      <MDBox pb={3}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <MDBox mb={1}>
              <MDTypography variant="h5">Some of Our Awesome Projects</MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text">
                This is the paragraph where you can write more details about your projects. Keep you
                user engaged by providing meaningful information.
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={5} sx={{ textAlign: 'right' }}>
            <CreateModal handleChange={onLoad} />
            <MDButton
              sx={{ marginLeft: 3 }}
              variant="outlined"
              color="info"
              onClick={copyToClipboard}
            >
              {copy ? 'Copied' : 'Copy Script'}
            </MDButton>
          </Grid>
        </Grid>
        {processing ? (
          <Loader />
        ) : (
          <MDBox mt={5}>
            <Grid container spacing={3}>
              {Apps.map((App) => (
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={1.5} mt={1.5}>
                    <ComplexProjectCard
                      image={logoSlack}
                      title={App.app_name}
                      description={App.app_id}
                      // members={[team1, team2, team3, team4, team5]}
                      dateTime={App.createdAt.split('T')[0]}
                      dropdown={{
                        action: openSlackBotMenu,
                        menu: renderMenu(slackBotMenu, closeSlackBotMenu, App),
                      }}
                    />
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </MDBox>
        )}
      </MDBox>
    </>
  )
}

export default AllProjects
