import React from 'react'
import { Card, Grid, Icon, Tooltip } from '@mui/material'
import Loader from 'examples/Loader'

import { useEffect, useState } from 'react'
import { useAppServices } from 'hook/services'
import { useNavigate, useParams } from 'react-router-dom'
import DefaultStatisticsCard from 'examples/Cards/StatisticsCards/DefaultStatisticsCard'
import VerticalBarChart from 'examples/Charts/BarCharts/VerticalBarChart'
import verticalBarChartData from '../charts/data/verticalBarChartData'
import MDTypography from 'components/MDTypography'
import DataTable from 'examples/Tables/DataTable'
import MDBox from 'components/MDBox'
import dataTableData from './data/dataTableData'
import defaultLineChartData from './data/defaultLineChartData'
import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart'
import MDBadgeDot from 'components/MDBadgeDot'
import MDButton from 'components/MDButton'
import { column } from 'stylis'
function Home() {
  const { location_id, app_id } = useParams()
  const Service = useAppServices()
  const [processing, setProcessing] = useState(true)
  const [monthlyRevenue, setMonthlyRevenue] = useState({
    labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Revenue Generated', // You can change this label as needed
        color: 'primary', // Use any color you prefer, or leave it as default
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Replace with actual revenue data
      },
    ],
  })
  const [top5AppsWithDetails, setTop5AppsWithDetails] = useState({
    columns: [
      { Header: 'app', accessor: 'app_name', width: '55%' },
      { Header: 'revenue', accessor: 'total_revenue', align: 'center' },
    ],
    rows: [],
  })
  const [cardData, setCardData] = useState([
    { title: 'Total Apps', value: 0 },
    { title: 'Total Users', value: 0 },
    { title: 'Total Locations', value: 0 },
    { title: 'Total Companies', value: 0 },
  ])
  const makeTop5RevenueGenreatedAppData = (response) => {
    // Step 1: Create a map of app_id to app data for quick lookup
    const appMap = response?.data?.reduce((map, app) => {
      map[app.app_id] = app
      return map
    }, {})

    // Step 2: Initialize a map for app revenue, starting at 0 for each app
    const appRevenueMap = response?.data?.reduce((accumulator, app) => {
      accumulator[app.app_id] = 0 // Initialize all apps with 0 revenue
      return accumulator
    }, {})

    // Step 3: Calculate total revenue for each app using `response.tokens`
    response?.tokens.forEach((token) => {
      const app = appMap[token?.app_id] // Directly access the app from appMap
      if (app) {
        const plans = app?.plans || []
        const plan = plans?.find((item) => item?.plan_id == token?.Plan_ID)

        if (plan && plan.price) {
          const revenue = parseFloat(plan.price)
          // Add the revenue to the app's total revenue
          if (appRevenueMap[token.app_id] !== undefined) {
            appRevenueMap[token.app_id] += revenue
          }
        }
      }
    })
    // Step 4: Convert the revenue map to an array of { app_id, total_revenue }
    const appRevenueArray = Object.entries(appRevenueMap).map(([app_id, total_revenue]) => ({
      app_id: app_id,
      total_revenue: total_revenue,
    }))

    // Step 5: Sort by total revenue in descending order
    const sortedRevenue = appRevenueArray.sort((a, b) => b.total_revenue - a.total_revenue)

    // Step 6: Get the top 5 revenue-generating apps
    const top5Apps = sortedRevenue.slice(0, 5)

    // Step 7: (Optional) Map top 5 apps back to the app data for more info (like app name, etc.)
    const top5AppsWithDetails = top5Apps.map((app) => {
      const appDetails = appMap[app.app_id] // Access app details directly from appMap
      return {
        app_name: (
          <MDTypography variant="button" fontWeight="regular" color="text" ml={2}>
            {appDetails?.app_name}
          </MDTypography>
        ),
        total_revenue: (
          <MDTypography variant="button" fontWeight="regular" color="text">
            ${app.total_revenue.toFixed(2)}
          </MDTypography>
        ),
      }
    })

    // Log and update state with the result

    setTop5AppsWithDetails({
      columns: [
        { Header: 'App', accessor: 'app_name', width: '55%' },
        { Header: 'Revenue', accessor: 'total_revenue', align: 'center' },
      ],
      rows: top5AppsWithDetails,
    })
  }

  const getData = async () => {
    const { response } = await Service.snapshot_onboarding.get_main_dashboard_data({
      query: `location_id=${location_id}&main_app_id=${app_id}`,
    })
    if (response) {
      console.log(response, 'response')
      const activeTokens = response?.tokens?.filter((token) => !token.deleted_at)
      const deletedTokens = response?.tokens?.filter((token) => token.deleted_at != undefined)
      // const totalRevenue = response?.tokens.reduce((accumulator, token) => {
      //   const app = response?.data?.find((item) => item?.app_id == token?.app_id)
      //   const plans = app?.plans || []
      //   if (plans?.length) {
      //     const plan = plans?.find?.((item) => item?.plan_id == token?.Plan_ID)
      //     return accumulator + parseFloat(plan.price)
      //   }
      // }, 0)
      let totalRevenue = 0

      // Initialize an array with 12 months, all set to 0.
      const monthsData = Array(12).fill(0)

      // Assuming response?.tokens is an array of tokens with a createdAt and Plan_ID
      response?.tokens.forEach((token) => {
        // Find the corresponding app and plan based on the token
        const app = response?.data?.find((item) => item?.app_id == token?.app_id)
        const plans = app?.plans || []
        const plan = plans?.find((item) => item?.plan_id == token?.Plan_ID)

        if (plan) {
          // Extract the price or revenue value from the plan (assuming there's a price field)
          const revenue = parseFloat(plan?.price) || 0
          totalRevenue += revenue

          // Extract the month from the createdAt field
          const createdAt = new Date(token?.createdAt)
          const monthIndex = createdAt.getMonth() // 0 for January, 1 for February, ..., 11 for December

          // Add the revenue to the correct month
          monthsData[monthIndex] += revenue
        }
      })

      // Print the total revenue and the monthly breakdown
      console.log('Total Revenue:', totalRevenue)
      console.log('Monthly Revenue:', monthsData)
      const finalData = {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Revenue Generated', // You can change this label as needed
            color: 'primary', // Use any color you prefer, or leave it as default
            data: monthsData, // Replace with actual revenue data
          },
        ],
      }
      console.log(finalData, 'finalData')
      setMonthlyRevenue(finalData)
      await makeTop5RevenueGenreatedAppData(response)

      setCardData([
        { title: 'Total Apps', value: response?.data?.length || 0 },
        { title: 'Total Installed Apps', value: response?.tokens?.length || 0 },
        { title: 'Total Active Apps', value: activeTokens?.length || 0 },
        { title: 'Total Deleted Apps', value: deletedTokens?.length || 0 },
        { title: 'Total Users', value: response?.users?.length || 0 },
        { title: 'Total Locations', value: response?.locations?.length || 0 },
        { title: 'Total Companies', value: response?.companies?.length || 0 },
        { title: 'Total Revenue Generated', value: `$${totalRevenue}` },
      ])
      setProcessing(false)
    }
  }

  const onLoad = () => {
    getData()
  }
  useEffect(async () => {
    onLoad()
  }, [])

  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <>
          Dashboard
          <hr />
          <div className="mt-5">
            <Grid container spacing={3}>
              {cardData.map((item, index) => (
                <Grid
                  key={index}
                  item
                  xs={12} // 1 card at small screens
                  sm={6} // 2 cards at medium screens
                  lg={4} // 3 cards at large screens
                >
                  <DefaultStatisticsCard title={item.title} count={item.value} />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} md={6} lg={6}>
                <Card sx={{ minHeight: '400px' }}>
                  <MDBox pt={3} px={3}>
                    <MDTypography variant="h6" fontWeight="medium">
                      Top 5 Revenue Generated Apps
                    </MDTypography>
                  </MDBox>
                  <MDBox py={1}>
                    <DataTable
                      table={top5AppsWithDetails}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      isSorted={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <DefaultLineChart
                  title="Revenue"
                  description={
                    <MDBox display="flex" justifyContent="space-between">
                      <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                        <Tooltip title="See which ads perform better" placement="left" arrow>
                          <MDButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            circular
                            iconOnly
                          >
                            <Icon>priority_high</Icon>
                          </MDButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                  }
                  chart={monthlyRevenue}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  )
}

export default Home
