/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sales dashboard components

// Images
// import nikeV22 from "assets/images/ecommerce/blue-shoe.jpeg";
// import businessKit from "assets/images/ecommerce/black-mug.jpeg";
// import blackChair from "assets/images/ecommerce/black-chair.jpeg";
// import wirelessCharger from "assets/images/ecommerce/bang-sound.jpeg";
// import tripKit from "assets/images/ecommerce/photo-tools.jpeg";
// import DefaultCell from "../components/DefaultCell";
// import ProductCell from "../components/ProductCell";
// import RefundsCell from "../components/RefundsCell";

// const dataTableData = {
//   columns: [
//     { Header: "app", accessor: "app", width: "55%" },
//     { Header: "price", accessor: "price" },
//     { Header: "total installs", accessor: "totalinstall", align: "center" },
//     { Header: "revenue generated", accessor: "revenuegenerated", align: "center" },
//   ],

//   rows: [
//     {
//       app: <ProductCell image={nikeV22} name="Nike v22 Running" orders={8.232} />,
//       price: <DefaultCell>$130.992</DefaultCell>,
//       totalinstall: <DefaultCell>31</DefaultCell>,
//       revenuegenerated: <DefaultCell>$91.300</DefaultCell>,
//     },
//     {
//       app: (
//         <ProductCell image={businessKit} name="Business Kit (Mug + Notebook)" orders={12.821} />
//       ),
//       price: <DefaultCell>$80.250</DefaultCell>,
//       totalinstall: <DefaultCell>20</DefaultCell>,
//       revenuegenerated: <DefaultCell>$91.300</DefaultCell>,
//     },
//     {
//       app: <ProductCell image={blackChair} name="Black Chair" orders={2.421} />,
//       price: <DefaultCell>$40.600</DefaultCell>,
//       totalinstall: <DefaultCell>14</DefaultCell>,
//       revenuegenerated: <DefaultCell>$91.300</DefaultCell>,
//     },
//     {
//       app: <ProductCell image={wirelessCharger} name="Wireless Charger" orders={5.921} />,
//       price: <DefaultCell>$91.300</DefaultCell>,
//       totalinstall: <DefaultCell>12</DefaultCell>,
//       revenuegenerated: <DefaultCell>$91.300</DefaultCell>,
//     },
//     {
//       app: (
//         <ProductCell image={tripKit} name="Mountain Trip Kit (Camera + Backpack)" orders={921} />
//       ),
//       price: <DefaultCell>$140.925</DefaultCell>,
//       totalinstall: <DefaultCell>10</DefaultCell>,
//       revenuegenerated: <DefaultCell>$91.300</DefaultCell>,
//     },
//   ],
// };

// export default dataTableData;

import nikeV22 from 'assets/images/ecommerce/blue-shoe.jpeg'
import businessKit from 'assets/images/ecommerce/black-mug.jpeg'
import blackChair from 'assets/images/ecommerce/black-chair.jpeg'
import wirelessCharger from 'assets/images/ecommerce/bang-sound.jpeg'
import tripKit from 'assets/images/ecommerce/photo-tools.jpeg'
import DefaultCell from '../components/DefaultCell'
import ProductCell from '../components/ProductCell'
import RefundsCell from '../components/RefundsCell'

const dataTableData = {
  columns: [
    { Header: 'app', accessor: 'app_name', width: '55%' },
    { Header: 'revenue', accessor: 'total_revenue', align: 'center' },
  ],

  // Sorted rows by revenue generated in descending order
  rows: [
    {
      app: <ProductCell image={nikeV22} name="Nike v22 Running" orders={31} />,
      price: <DefaultCell>$130.992</DefaultCell>,
      // totalinstall: <DefaultCell>31</DefaultCell>,
      revenuegenerated: <DefaultCell>$243,992</DefaultCell>,
    },
    {
      app: <ProductCell image={businessKit} name="Business Kit (Mug + Notebook)" orders={20} />,
      price: <DefaultCell>$80.250</DefaultCell>,
      // totalinstall: <DefaultCell>20</DefaultCell>,
      revenuegenerated: <DefaultCell>$160,500</DefaultCell>,
    },
    {
      app: <ProductCell image={blackChair} name="Black Chair" orders={14} />,
      price: <DefaultCell>$40.600</DefaultCell>,
      // totalinstall: <DefaultCell>14</DefaultCell>,
      revenuegenerated: <DefaultCell>$56,840</DefaultCell>,
    },
    {
      app: <ProductCell image={wirelessCharger} name="Wireless Charger" orders={12} />,
      price: <DefaultCell>$91.300</DefaultCell>,
      // totalinstall: <DefaultCell>12</DefaultCell>,
      revenuegenerated: <DefaultCell>$1,095,600</DefaultCell>,
    },
    {
      app: <ProductCell image={tripKit} name="Mountain Trip Kit (Camera + Backpack)" orders={10} />,
      price: <DefaultCell>$140.925</DefaultCell>,
      // totalinstall: <DefaultCell>10</DefaultCell>,
      revenuegenerated: <DefaultCell>$1,409,250</DefaultCell>,
    },
  ].sort((a, b) => {
    const revenueA = parseFloat(a.revenuegenerated.props.children.replace(/[^0-9.-]+/g, ''))
    const revenueB = parseFloat(b.revenuegenerated.props.children.replace(/[^0-9.-]+/g, ''))
    return revenueB - revenueA // Sort in descending order
  }),
}

export default dataTableData
