import MDButton from 'components/MDButton'
import AddBoxIcon from '@mui/icons-material/AddBox'
import React, { useEffect, useState } from 'react'
import MDModal from 'components/MDModal'
import MDInput from 'components/MDInput'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import { useParams } from 'react-router-dom'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
const PlansModal = (props) => {
  const Service = useAppServices()
  const { location_id, app_id, id } = useParams()
  const [openAddProduct, setOpenAddProduct] = useState(false)
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false)
    const handleSubmit = async (e) => {
      e.preventDefault()
      setProcessing(true)
      let plans = []
      if (props?.pricing?.pricing?.plans) {
        plans = props?.pricing?.pricing?.plans
      }
      const plan = {
        name: e.target.name.value,
        plan_id: e.target.plan_id.value,
        price: e.target.price.value,
        pricing: e.target.pricing.value,
      }
      plans.push(plan)
      const pricing = {
        ...props?.pricing?.pricing,
        plans: plans,
      }
      const payload = {
        pricing: pricing,
        _id: id,
      }
      const { response } = await Service.snapshot_onboarding.update({ payload })
      if (response) {
        setOpenAddProduct(false)
        setProcessing(false)
        props.handleChange()
      } else {
        setProcessing(false)
      }
    }
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Plan
          </MDTypography>

          <MDBox component="form" onSubmit={handleSubmit} role="form" sx={{ width: 335 }}>
            <Grid item md={12}>
              <MDBox mb={3}>
                <MDTypography sx={{ fontWeight: 500, fontSize: '15px' }}>Pricing</MDTypography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="pricing"
                  >
                    {props?.appType && props?.appType == 'freemium' && (
                      <FormControlLabel value="free" control={<Radio />} label="Free" />
                    )}
                    <FormControlLabel value="one-time" control={<Radio />} label="One-time" />
                    <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                    <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
                  </RadioGroup>
                </FormControl>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Plan Name
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Plan Name"
                  name="name"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Plan ID
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Plan ID"
                  name="plan_id"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Plan Price
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="Plan Price"
                  name="price"
                />
              </MDBox>
            </Grid>
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={processing}
                loading={processing}
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    )
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false)
  }
  return (
    <>
      <MDButton
        sx={{ marginLeft: 3 }}
        variant="outlined"
        color="info"
        startIcon={<AddBoxIcon />}
        onClick={() => setOpenAddProduct(true)}
      >
        Plan
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  )
}

export default PlansModal
