import PageLayout from 'examples/LayoutContainers/PageLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import { Grid } from '@mui/material'
import Loader from 'examples/Loader'
import MUIDataTable from 'mui-datatables'
// import "../Users/style.css";
import routes from './routes'

import { useEffect, useState } from 'react'
import { useAgencyInfo } from 'context/agency'
import { useAppServices } from 'hook/services'
import MDButton from 'components/MDButton'
import { useNavigate, useParams } from 'react-router-dom'
import EditModal from './components/Modals/Edit'
import env from 'config'
import Sidenav from 'examples/Sidenav'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import AppsComponent from './components/pages/AppsComponent'
function Apps() {
  const navigate = useNavigate()
  const { location_id, app_id } = useParams()
  // const [agency] = useAgencyInfo()
  const Service = useAppServices()

  const [Conjo, setConjo] = useState([])
  const [data, setdata] = useState([])
  const [agency, setAency] = useState({})
  const [processing, setProcessing] = useState(true)
  const [copy, setCopy] = useState(false)
  const copyToClipboard = (url) => {
    const text = url

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.setAttribute('readonly', '')
    textarea.style.position = 'absolute'
    textarea.style.left = '-9999px'

    // Append the textarea to the body
    document.body.appendChild(textarea)

    // Select the text inside the textarea
    textarea.select()

    // Execute the copy command
    document.execCommand('copy')
    setCopy(true)
    // Remove the textarea from the DOM
    document.body.removeChild(textarea)

    setTimeout(() => {
      setCopy(false)
    }, 2000)
  }
  const columns = [
    {
      name: 'App ID',
      options: {
        filter: false,
      },
    },
    {
      name: 'App Name',
      options: {
        filter: false,
      },
    },
    // {
    //     name: 'No Of Plans',
    //     options: {
    //         filter: false,
    //     },
    // },
    {
      name: 'Custom Page Url',
      options: {
        filter: false,
      },
    },
    {
      name: 'Redirect Url',
      options: {
        filter: false,
      },
    },
    {
      name: 'Webhook Url',
      options: {
        filter: false,
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'standard',
  }
  const getData = async () => {
    const { response } = await Service.snapshot_onboarding.get({
      query: `location_id=${location_id}&main_app_id=${app_id}`,
    })

    if (response) {
      var tabel_data = []
      response.data.forEach((element) => {
        const temp = [
          element.app_id,
          element.app_name,
          // element.plans?.length,
          <>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              ml={2}
              onClick={() =>
                copyToClipboard(
                  `https://snapshotonboarding.levelupmarketplace.io/${element?.app_id}/onboarding/redirect`
                )
              }
            >
              Copy
            </MDButton>
          </>,
          <>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              ml={2}
              onClick={() =>
                copyToClipboard(
                  `https://snapshotonboarding.levelupmarketplace.io/integrations/auth/snapshot_onboarding/${element.app_id}`
                )
              }
            >
              Copy
            </MDButton>
          </>,
          <>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              ml={2}
              onClick={() =>
                copyToClipboard(
                  `https://marketplace.ghl.store/api/v1/webhook/snapshot_onboarding_installevent`
                )
              }
            >
              Copy
            </MDButton>
          </>,
          <MDButton display="flex">
            <MDButton
              variant="contained"
              color="info"
              size="small"
              onClick={() => navigate(`./pricing/${element._id}`)}
            >
              {' '}
              pricing
            </MDButton>
            <MDButton
              sx={{ marginLeft: '4px' }}
              variant="contained"
              color="success"
              size="small"
              onClick={() => navigate(`./${element._id}`)}
            >
              {' '}
              View
            </MDButton>
            <EditModal editData={element} handleRefresh={getData} />
            <MDButton
              variant="contained"
              color="error"
              size="small"
              sx={{ marginLeft: '4px' }}
              onClick={() => hadleDelete(element._id)}
            >
              Delete
            </MDButton>
          </MDButton>,
        ]
        tabel_data.push(temp)
      })
      setConjo(tabel_data)
      setAency(response.agency)
      setProcessing(false)
    } else {
      setProcessing(false)
    }
  }
  const onLoad = () => {
    getData()
  }
  useEffect(async () => {
    onLoad()
  }, [])
  const hadleDelete = async (id) => {
    const { response } = await Service.snapshot_onboarding.delete({
      query: `_id=${id}`,
    })
    if (response) {
      getData()
    }
  }
  const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.ASSOCIATE}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.SCOPE}&state=${agency?._id}`
  const hanldeConnect = () => {
    var windowFeatures = 'width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes'

    // Open the URL in a new window with the specified features
    window.open(auth_url, '_blank', windowFeatures)
  }

  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <>
          <AppsComponent onLoad={onLoad} Conjo={Conjo} columns={columns} options={options} />
        </>
      )}
    </>
  )
}

export default Apps
