/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// Settings page components
import BaseLayout from '../components/BaseLayout'
import Sidenav from './components/Sidenav'
import Header from './components/Header'
import BasicInfo from './components/BasicInfo'
import ChangePassword from './components/ChangePassword'
import Authentication from './components/Authentication'
import Accounts from './components/Accounts'
import Profiles from './components/Profiles'
import Sessions from './components/Sessions'
import DeleteAccount from './components/DeleteAccount'
import AppConfiguration from './components/AppConfiguration'
import { useEffect, useState } from 'react'
import Loader from 'examples/Loader'
import { useAppServices } from 'hook/services'
import { useNavigate, useParams } from 'react-router-dom'
import MDButton from 'components/MDButton'

function Settings() {
  const navigate = useNavigate()
  const { location_id, id, app_id } = useParams()
  const Service = useAppServices()
  const [PlansTableData, setPlansTableData] = useState([])
  const [data, setdata] = useState([])
  const [processing, setProcessing] = useState(true)
  const getData = async () => {
    const { response } = await Service.snapshot_onboarding.filter({
      query: `_id=${id}`,
    })
    console.log(response, 'response')
    if (response) {
      var tabel_data = []
      let plans_data = []
      if (response.data?.plans) {
        plans_data = response.data?.plans
      }
      plans_data.forEach((element, index) => {
        const temp = [
          element.name,
          element.price,
          element.plan_id,
          <>
            <MDButton
              variant="contained"
              color="error"
              size="small"
              sx={{ marginLeft: '4px' }}
              onClick={() => hadleDelete(index)}
            >
              Delete
            </MDButton>
          </>,
        ]
        tabel_data.push(temp)
      })
      setPlansTableData(tabel_data)
      setdata(response.data)
      setProcessing(false)
    } else {
      setProcessing(false)
    }
  }
  const hadleDelete = async (index) => {
    const plans_data = data
    if (index > -1) {
      plans_data.splice(index, 1)
    }
    const payload = {
      plans: plans_data,
      _id: id,
    }
    const { response } = await Service.snapshot_onboarding.update({ payload })
    if (response) {
      getData()
    }
  }

  const onLoad = () => {
    getData()
  }
  useEffect(async () => {
    onLoad()
  }, [])
  const hadleBack = async () => {
    navigate(`/${location_id}/${app_id}/onboarding`)
  }
  return (
    <MDBox mt={4}>
      {processing ? (
        <Loader />
      ) : (
        <>
          <MDBox mb={2}>
            <MDButton variant="gradient" color="dark" sx={{ height: '100%' }} onClick={hadleBack}>
              Go Back
            </MDButton>
          </MDBox>

          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Sidenav />
            </Grid>
            <Grid item xs={12} lg={9}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Header data={data} handleRefresh={onLoad} />
                  </Grid>
                  {/* <Grid item xs={12}>
                  // <BasicInfo />
                </Grid> */}
                  <Grid item xs={12}>
                    <ChangePassword data={data} handleChange={onLoad} />
                  </Grid>

                  <Grid item xs={12}>
                    <AppConfiguration data={data} handleChange={onLoad} />
                  </Grid>
                  <Grid item xs={12}>
                    <Accounts PlansTableData={PlansTableData} data={data} handleChange={onLoad} />
                  </Grid>
                  <Grid item xs={12}>
                    <Authentication data={data} />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Profiles data={data} />
                  </Grid> */}
                  <Grid item xs={12}>
                    <DeleteAccount />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </>
      )}
    </MDBox>
  )
}

export default Settings
