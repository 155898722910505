import { Card, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React, { useEffect, useState } from 'react'
import PlansComponent from './components/pages/PlansComponent'
import { useParams } from 'react-router-dom'
import { useAppServices } from 'hook/services'
import Loader from 'examples/Loader'
import MDButton from 'components/MDButton'

const index = () => {
  const { location_id, id } = useParams()
  const Service = useAppServices()
  const [Conjo, setConjo] = useState([])
  const [data, setdata] = useState({})
  const [processing, setProcessing] = useState(true)
  const [appType, setAppType] = useState('free')
  const [freeTrail, setFreeTrail] = useState('no')
  const [freeTrailDays, setFreeTrailDays] = useState(0)
  const columns = [
    {
      name: 'Name',
      options: {
        filter: false,
      },
    },
    {
      name: 'Price',
      options: {
        filter: false,
      },
    },
    {
      name: 'Plan Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'Pricing',
      options: {
        filter: false,
      },
    },
    {
      name: 'Action',
      options: {
        filter: false,
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'standard',
  }
  const getData = async () => {
    const { response } = await Service.snapshot_onboarding.filter({
      query: `location_id=${location_id}&_id=${id}`,
    })
    console.log(response, 'response')
    if (response) {
      var tabel_data = []
      let plans_data = []
      if (response.data?.pricing?.plans) {
        plans_data = response.data?.pricing?.plans
      }
      plans_data.forEach((element, index) => {
        const temp = [
          element.name,
          element.price,
          element.plan_id,
          element.pricing,
          <>
            <MDButton
              variant="contained"
              color="error"
              size="small"
              sx={{ marginLeft: '4px' }}
              onClick={() => hadleDelete(index, response?.data)}
            >
              Delete
            </MDButton>
          </>,
        ]
        tabel_data.push(temp)
      })
      setConjo(tabel_data)
      if (response?.data) {
        setdata(response?.data)
        setAppType(response?.data?.pricing?.appType)
        setFreeTrail(response?.data?.pricing?.freeTrail)
        setFreeTrailDays(response?.data?.pricing?.freeTrailDays)
      }
      setProcessing(false)
    } else {
      setProcessing(false)
    }
  }
  const onLoad = () => {
    getData()
  }
  useEffect(async () => {
    onLoad()
  }, [])
  const hadleDelete = async (index, data) => {
    const plans_data = data?.pricing?.plans
    if (index > -1) {
      plans_data.splice(index, 1)
    }
    const pricing = {
      ...data?.pricing,
      plans: plans_data,
    }
    const payload = {
      pricing: pricing,
      _id: id,
    }
    const { response } = await Service.snapshot_onboarding.update({ payload })
    if (response) {
      getData()
    }
  }
  const handleSubmit = async () => {
    const plans_data = data?.pricing?.plans
    const pricing = {
      appType: appType,
      freeTrail: freeTrail,
      freeTrailDays: freeTrailDays,
      plans: plans_data,
    }
    const payload = {
      pricing: pricing,
      _id: id,
    }
    const { response } = await Service.snapshot_onboarding.update({ payload })
    if (response) {
      getData()
    }
  }

  return (
    <MDBox mt={4}>
      {processing ? (
        <Loader />
      ) : (
        <Card sx={{ padding: '1.2rem', paddingTop: '1.5rem' }}>
          <MDBox mb={3}>
            <MDTypography component="h1" variant="h5" sx={{ fontWeight: 500 }}>
              Pricing
            </MDTypography>
            <MDTypography sx={{ fontSize: '13px' }}>
              Tell us about your app's business model and pricing plans, then set up a pricing page
            </MDTypography>
          </MDBox>
          <MDBox mb={3}>
            <MDTypography sx={{ fontWeight: 500, fontSize: '15px' }}>
              Is your app free or paid?
            </MDTypography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => setAppType(e.target.value)}
                value={appType}
              >
                <FormControlLabel value="free" control={<Radio />} label="Free" />
                <FormControlLabel value="freemium" control={<Radio />} label="Freemium" />
                <FormControlLabel value="paid" control={<Radio />} label="Paid" />
              </RadioGroup>
            </FormControl>
          </MDBox>
          {appType == 'freemium' || appType == 'paid' ? (
            <>
              <MDBox mb={1}>
                <MDBox mb={1}>
                  <MDTypography component="h1" variant="h5" sx={{ fontWeight: 500 }}>
                    Pricing Plans
                  </MDTypography>
                  <MDTypography sx={{ fontSize: '13px' }}>
                    We support a combination of free and paid plans to help you strategize better. A
                    freemium app requires at least one free plan and one paid plan.
                  </MDTypography>
                </MDBox>
                <PlansComponent
                  onLoad={onLoad}
                  Conjo={Conjo}
                  pricing={data}
                  columns={columns}
                  options={options}
                  appType={appType}
                />
              </MDBox>
              <MDBox mb={2} mt={4}>
                <MDTypography component="h1" variant="h6" sx={{ fontWeight: 500 }}>
                  Do you want to provide a free trial for your app?
                </MDTypography>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => setFreeTrail(e.target.value)}
                    value={freeTrail}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                {freeTrail == 'yes' && (
                  <MDBox>
                    <MDTypography sx={{ fontSize: '13px' }} mb={1}>
                      Days of free trail
                    </MDTypography>
                    <MDBox display="flex" gap={2} width={'100px'} alignItem="center">
                      <TextField
                        type="number"
                        fullWidth
                        name="free_trail_days"
                        value={freeTrailDays}
                        onChange={(e) => setFreeTrailDays(e.target.value)}
                      />
                      <MDTypography mt={1} sx={{ fontSize: '13px' }}>
                        Days
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                )}
              </MDBox>
            </>
          ) : (
            <></>
          )}
          <MDBox mb={3} mt={3} display="flex" justifyContent="end">
            <MDButton variant="gradient" color="info" type="button" onClick={handleSubmit}>
              Save
            </MDButton>
          </MDBox>
        </Card>
      )}
    </MDBox>
  )
}

export default index
