import Loader from "examples/Loader";
import { useAppServices } from "hook/services";
import MUIDataTable$1 from "mui-datatables";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Locations() {
    const Service = useAppServices()
    console.log(Service, 'Service')
    const { location_id, app_id } = useParams()
    const [Conjo, setConjo] = useState([])
    const [processing, setProcessing] = useState(true)

    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none',
        filterType: 'multiselect',
        responsive: 'standard',
    }
    const columns = [
        {
            name: 'Location ID',
            options: {
                filter: false,
            },
        },
        {
            name: 'App ID',
            options: {
                filter: false,
            },
        },
        {
            name: 'Company ID',
            options: {
                filter: false,
            },
        }
    ]

    const getData = async () => {
        const { response } = await Service.snapshot_onboarding.get({
            query: `location_id=${location_id}&app_id=${app_id}`,
        });
        console.log(response.locations, 'response')
        if (response) {
            var tabel_data = []
            response.locations.forEach((element) => {
                const temp = [
                    element.location_id,
                    element.app_id,
                    element.company_id,
                ]
                tabel_data.push(temp)
            })
            setConjo(tabel_data)
            setProcessing(false)
        } else {
            setProcessing(false)
        }
    }

    useEffect(async () => {
        getData()
    }, [])


    return (
        <>
            {processing ? (
                <Loader />
            ) : (
                <>
                    <h2 className="mb-5">Locations</h2>
                    <MUIDataTable$1 title={'Locations'} data={Conjo} columns={columns} options={options} />
                </>
            )}
        </>
    )
}

export default Locations;