import React from 'react'
import Settings from './components/pages/accounts/settings'
function AppDetails() {
  return (
    <>
      <Settings />
    </>
  )
}

export default AppDetails
