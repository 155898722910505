import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import Loader from 'examples/Loader'

import { useEffect, useState } from 'react'
import { useAppServices } from 'hook/services'
import { useNavigate, useParams } from 'react-router-dom'
function AppDetails() {
    const { location_id, app_id, id } = useParams()
    const Service = useAppServices()
    const [processing, setProcessing] = useState(true)

    let [cardData, setCardData] = useState(
        [
            { title: 'App Name', value: "" },
            { title: 'Total Users', value: 0 },
            { title: 'Total Locations', value: 0 },
            { title: 'Total Companies', value: 0 },
        ]
    )

    const getData = async () => {
        const { response } = await Service.snapshot_onboarding.get_app_dashboard({
            query: `location_id=${location_id}&_id=${id}`,
        })
        if (response) {
            console.log(response, 'response')

            setCardData(
                [
                    { title: 'App Name', value: response.data?.app_name },
                    { title: 'Total Users', value: response.users.length },
                    { title: 'Total Locations', value: response.locations.length },
                    { title: 'Total Companies', value: response.companies.length },
                ]
            )


            setProcessing(false)

        }
    }

    const onLoad = () => {
        getData()
    }
    useEffect(async () => {
        onLoad();
    }, [])

    return (
        <>
            {processing ? (
                <Loader />
            ) : (
                <>
                    Dashboard
                    <hr />

                    <div className='mt-5'>
                        {/* total apps */}
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 6 }}>
                            {cardData.map((item, index) => (
                                <Grid item key={index} xl={6} lg={3} md={2}>
                                    <Card sx={{ minHeight: "8rem" }}>
                                        <CardContent>
                                            <Grid container alignItems="center">
                                                <Grid item xs={6}>
                                                    <Typography variant="h6" textTransform="uppercase" component="div">
                                                        {item.title}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <br />
                                            <Typography color="blue" variant="h3" align='right' component="div">
                                                {item.value}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </div>

                </>
            )}
        </>
    )
}

export default AppDetails
