import PageLayout from 'examples/LayoutContainers/PageLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import { Grid } from '@mui/material'
import Loader from 'examples/Loader'
import { useEffect, useState } from 'react'
import { useAppServices } from 'hook/services'
import MDButton from 'components/MDButton'
import { useNavigate, useParams } from 'react-router-dom'
import env from 'config'
import PlansComponent from './components/pages/PlansComponent'
function Plans() {
    const navigate = useNavigate()
    const { location_id, app_id, id } = useParams()
    // const [agency] = useAgencyInfo()
    const Service = useAppServices()


    const [Conjo, setConjo] = useState([])
    const [data, setdata] = useState([])
    const [agency, setAency] = useState({})
    const [processing, setProcessing] = useState(true)
    const [copy, setCopy] = useState(false)
    const copyToClipboard = (url) => {
        const text = url

        // Create a temporary textarea element to hold the text
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';

        // Append the textarea to the body
        document.body.appendChild(textarea);

        // Select the text inside the textarea
        textarea.select();

        // Execute the copy command
        document.execCommand('copy');
        setCopy(true)
        // Remove the textarea from the DOM
        document.body.removeChild(textarea);

        setTimeout(() => {
            setCopy(false)
        }, 2000);
    };
    const columns = [
        {
            name: 'Name',
            options: {
                filter: false,
            },
        },
        {
            name: 'Price',
            options: {
                filter: false,
            },
        },
        {
            name: 'Plan Id',
            options: {
                filter: false,
            },
        },
        {
            name: 'Action',
            options: {
                filter: false,
            },
        },
    ]

    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none',
        filterType: 'multiselect',
        responsive: 'standard',
    }
    const getData = async () => {
        const { response } = await Service.snapshot_onboarding.get({
            query: `location_id=${location_id}&_id=${id}`,
        })
        console.log(response, 'response')
        if (response) {
            var tabel_data = []
            let plans_data = []
            if (response.data[0]?.plans) {
                plans_data = response.data[0]?.plans
            }
            plans_data.forEach((element, index) => {
                const temp = [
                    element.name,
                    element.price,
                    element.plan_id,
                    <>
                        <MDButton
                            variant="contained"
                            color="error"
                            size="small"
                            sx={{ marginLeft: '4px' }}
                            onClick={() => hadleDelete(index)}
                        >
                            Delete
                        </MDButton>
                    </>,
                ]
                tabel_data.push(temp)
            })
            setConjo(tabel_data)
            setdata(plans_data)
            setProcessing(false)
        } else {
            setProcessing(false)
        }
    }
    const onLoad = () => {
        getData()
    }
    useEffect(async () => {
        onLoad()
    }, [])
    const hadleDelete = async (index) => {
        const plans_data = data
        if (index > -1) {
            plans_data.splice(index, 1);
        }
        const payload = {
            plans: plans_data,
            _id: id,
        };
        const { response } = await Service.snapshot_onboarding.update({ payload });
        if (response) {
            getData()
        }
    }
    const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.ASSOCIATE}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.SCOPE}&state=${agency?._id}`
    const hanldeConnect = () => {
        var windowFeatures = 'width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes'

        // Open the URL in a new window with the specified features
        window.open(auth_url, '_blank', windowFeatures)
    }

    return (
        <>
            {processing ? (
                <Loader />
            ) : (
                <>
                    <PlansComponent onLoad={onLoad} Conjo={Conjo} plans={data} columns={columns} options={options} />
                </>
            )}
        </>
    )
}

export default Plans
